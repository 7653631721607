
  .main-footer{
    margin-top: 1rem;
    padding: 1rem;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .Mail-logo {
    height: 92px;
    width:  72px;
  }

  :root {
  --color-white: #fff;
  --color-black: #000;
  --color-background: rgb(246, 53, 236);
  --color-grey: #999;
  --color-blue: #39f;
  --color-yellow: #999;
  --base-background-color: var(--color-black);
  --base-font-family: "Pixelo";
  --base-font-size: 2rem;
  --font-size: 18px;
  --base-line-height: 1.2;
  --base-text-color: var(--color-black);
  --base-min-width: 320px;
  --headings-font-family: "Pixelo";
  --headings-sizes-h1: 6rem;
  --headings-sizes-h2: 5rem;
  --headings-sizes-h3: 3rem;
  --headings-sizes-h4: 2.5rem;
  --headings-sizes-h5: 2rem;
  --headings-sizes-h6: 1.8rem;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 1rem;
  height: 0.4rem;
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background: var(--color-yellow);
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-yellow);
}

html {
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--base-text-color);
  background: var(--base-background-color);
  font: var(--base-font-size)/var(--base-line-height) var(--base-font-family);
  min-width: var(--base-min-width);
}

img {
  max-width: 100%;
  height: auto;
}

.gm-style img {
  max-width: none;
}


@font-face {
  font-family: 'NeuePixelGrotesk';
  src: url("../Fonts/NONNaturalMono-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica Neue LT W1G';
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/NONNaturalMono-Light.woff2") format("woff2"); }

@font-face {
  font-weight: 100;
  font-style:  normal;
  font-family: "CircularXXWeb-Book";
  src: url('../Fonts/NONNaturalMono-Light.woff2') format('woff2');
  font-family: "Pixelo";
  src: url('../Fonts/Pixelo.otf')
}

@font-face {
  font-family: "HelveticaNeueLTW1G-Roman";
  src: url('../Fonts/HelveticaNeueLTW1G-Roman.otf')
}

@font-face {
  font-family: "Pixelo";
  src: url('../Fonts/Pixelo.otf')
}


h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
  font-family: var(--headings-font-family);
  margin: 0;
}

h1, .h1 {
  font-size: var(--headings-sizes-h1);
}

h2, .h2 {
  font-size: var(--headings-sizes-h2);
}

h3, .h3 {
  font-size: var(--headings-sizes-h3);
}

h4, .h4 {
  font-size: var(--headings-sizes-h4);
}

h5, .h5 {
  font-size: var(--headings-sizes-h5);
}

h6, .h6 {
  font-size: var(--headings-sizes-h6);
}

p {
  margin: 0 0 1em;
}

a {
  color: inherit;
  transition: color 0.3s ease;
}
a:focus {
  text-decoration: none;
}
a:hover {
  color: var(--color-yellow);
}

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input[type=text],
input[type=tel],
input[type=email],
input[type=search],
input[type=password],
textarea {
  -webkit-appearance: none;
          appearance: none;
  box-sizing: border-box;
  font-family: var(--form-font-family);
  border-radius: 0;
  border: 0.1rem solid var(--form-element-border-color);
  padding: var(--form-element-padding);
}
input[type=text]:focus,
input[type=tel]:focus,
input[type=email]:focus,
input[type=search]:focus,
input[type=password]:focus,
textarea:focus {
  border-color: var(--form-element-focus-border-color);
}
input[type=text]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=search]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: var(--placeholder-color);
}
input[type=text]::-moz-placeholder,
input[type=tel]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=search]::-moz-placeholder,
input[type=password]::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: var(--placeholder-color);
}
input[type=text]:-moz-placeholder,
input[type=tel]:-moz-placeholder,
input[type=email]:-moz-placeholder,
input[type=search]:-moz-placeholder,
input[type=password]:-moz-placeholder,
textarea:-moz-placeholder {
  color: var(--placeholder-color);
}
input[type=text]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=search]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: var(--placeholder-color);
}
input[type=text].placeholder,
input[type=tel].placeholder,
input[type=email].placeholder,
input[type=search].placeholder,
input[type=password].placeholder,
textarea.placeholder {
  color: var(--placeholder-color);
}

select {
  border-radius: 0;
}

textarea {
  resize: vertical;
  vertical-align: top;
}

button,
input[type=button],
input[type=reset],
input[type=file],
input[type=submit] {
  -webkit-appearance: none;
          appearance: none;
  border-radius: 0;
  cursor: pointer;
  font-family: var(--form-font-family);
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
}

.content {
  flex: 1 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

.section {
  position: relative;
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
  max-width: 1440px;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  height: fit-content;
  width: 100%;
  z-index: 10;
  padding: 3rem 0 3rem 0;
  background-color: var(--color-background);
}
.header.scrolled {
  background-color: var(--color-black);
  padding-top: 2rem;
}
.header .container {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}
.header .logo {
  width: 17rem;
  height: 2.6rem;
  color: var(--color-white);
  transition: all 0.4s ease-in-out 0.8s;
}
.header .logo .icon {
  width: 100%;
  height: 100%;
}
.header .menu-button {
  position: relative;
  border: none;
  outline: none;
  background-color: transparent;
  width: 5.2rem;
  height: 2.4rem;
  display: inline-block;
  color: var(--color-white);
}
.header .menu-button span {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 5.2rem;
  height: 0.4rem;
  border-radius: 0;
  background-color: currentColor;
  transition: 0.25s cubic-bezier(0.43, 0.58, 0.47, 0.62);
}
.header .menu-button span:before, .header .menu-button span:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  transition: 0.5s cubic-bezier(0.43, 0.58, 0.47, 0.62);
  background-color: currentColor;
}
.header .menu-button span:before {
  transform: translateY(-1.2rem);
}
.header .menu-button span:after {
  transform: translateY(1.2rem);
}
.header .menu-button.active span {
  background-color: transparent;
}
.header .menu-button.active span:before {
  transform: rotate(135deg);
}
.header .menu-button.active span:after {
  transform: rotate(-135deg);
}
.logo {
  display: flex;
  align-items: center;
  align-content: center;
}
.logo picture,
.logo img {
  width: 100%;
  height: auto;
}
.footer {
  background-color: var(--color-black);
  color: var(--color-white);
  margin-top: 0rem;
  margin-bottom: 2rem;
  padding: 2rem;
}
.footer .line {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer .line:not(:last-child) {
  margin-bottom: 3.5rem;
}
.footer .copy {
  position: relative;
  display: inline-flex;
  align-items: center;
  align-content: center;
  min-width: max-content;
}
.footer .logo {
  width: 15rem;
  height: 2.6rem;
}
.footer .logo .icon {
  width: 100%;
  height: 100%;
}
.footer a {
  text-decoration: none;
}

.heroHeader {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  color: var(--color-white);
  height: fit-content;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: var(--color-background);
}


.heroDefault {
  position: relative;
  display: flex;
  padding-top: 4%;
  padding-bottom: 4%;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: var(--color-white);
  padding-left: 1%;
  padding-right: 1%;
  height: fit-content;
  background-color: #000000;
}

.heroImages {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: var(--color-white);
  height: 400px;
  background-color: #000000;
}

.hero {
  position: relative;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: var(--color-white);
  height: fit-content;
  padding-top: 4%;
  padding-bottom: 4%;
  background-color: var(--color-background);
}

.hero .container {
  z-index: 1;
}
.hero .text {
  position: relative;
}
.hero .text .line {
  position: relative;
  display: block;
  overflow: hidden;
}
.hero .text span {
  position: relative;
  display: block;
  transition-delay: calc(var(--i)*0.1s + 0.4s);
}
.hero-bg {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.hero-bg .video {
  z-index: 0;
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.hero-bg .video:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(272deg, rgba(53, 53, 104, 0) 0%, rgba(0, 0, 0, 0.86) 100%);
  z-index: 1;
}
.hero-bg video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.hero-bg iframe {
  width: 100vw;
  height: 56.25vw;
  /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh;
  /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.socials {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  width: 100%;
}
.socials .social {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
}
.socials .social:not(:last-child) {
  margin-right: 4rem;
}

.contact {
  position: relative;
  display: block;
}
.contact__item {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
}
.contact__item:not(:last-child) {
  margin-bottom: 2rem;
}

.menu {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 9rem 0;
  background-color: var(--color-black);
  color: var(--color-white);
  transition: all 0.4s ease-in-out 0.6s;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
}
.menu .container {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
}
.menu .container > * {
  margin: 3rem 6rem;
  transition: all 0.4s ease-in-out calc(var(--i)*0.1s);
  transform: translateY(5rem);
  opacity: 0;
}
.menu a {
  text-decoration: none;
}
.menu .logo {
  width: 30rem;
  height: auto;
}
.menu-open {
  overflow: hidden;
}
.menu-open .header .logo {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}
.menu-open .menu {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease-in-out;
}
.menu-open .menu .container > * {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s ease-in-out calc(var(--i)*500ms + 0.4s);
}

.about {
  position: relative;
  padding: 20rem 0;
  min-height: 100vh;
}
.about__content {
  position: relative;
}

.tabs {
  position: relative;
  display: flex;
}
.tabs__menu, .tabs__content {
  width: 50%;
}
.tabs__menu {
  position: relative;
}
.tabs__menu h3 {
  margin-bottom: 4rem !important;
}
.tabs__menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.tabs__menu ul li {
  position: relative;
  display: block;
  width: 100%;
  transition-delay: calc(var(--i)*0.1s);
}
.tabs__menu ul li:not(:last-child) {
  margin-bottom: 2rem;
}
.tabs__menu ul a {
  position: relative;
  display: inline-block;
  text-decoration: none;
}
.tabs__menu ul a:after {
  content: "";
  position: relative;
  display: inline-block;
  margin-left: 3rem;
  height: 0.4rem;
  bottom: 0;
  width: 0;
  background-color: var(--color-yellow);
  transition: all 0.3s ease;
}
.tabs__menu ul a.active {
  color: var(--color-yellow);
}
.tabs__menu ul a.active:after {
  width: 9rem;
}
.tabs__content {
  position: relative;
  display: block;
  min-height: 100%;
}
.tabs .tab {
  position: relative;
  opacity: 0;
  transition: all 0.3s ease;
  height: 0;
  overflow: hidden;
}
.tabs .tab-head-mobile {
  display: none;
}
.tabs .tab-head-mobile a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding-bottom: 1rem;
}
.tabs .tab-head-mobile a:after {
  content: "";
  position: absolute;
  display: inline-block;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  height: 0.4rem;
  bottom: 0;
  width: 0;
  background-color: var(--color-yellow);
  transition: all 0.3s ease;
}
.tabs .tab-head-mobile a.active {
  color: var(--color-yellow);
}
.tabs .tab-head-mobile a.active:after {
  width: 9rem;
}
.tabs .tab.show {
  display: block;
}
.tabs .tab.active {
  opacity: 1;
  visibility: visible;
}
.tabs .tab.active .tab__content h2, .tabs .tab.active .tab__content h3, .tabs .tab.active .tab__content h4, .tabs .tab.active .tab__content p {
  transform: translateY(0);
  opacity: 1;
}
.tabs .tab__content {
  position: relative;
}
.tabs .tab__content h2, .tabs .tab__content h3, .tabs .tab__content h4 {
  margin-bottom: 2rem;
  transform: translateY(3rem);
  opacity: 0;
  transition: all 0.3s ease 0.2s;
}
.tabs .tab__content p {
  font-size: 2.6rem;
  transform: translateY(3rem);
  opacity: 0;
  transition: all 0.3s ease 0.4s;
}
.tabs .tab__icon {
  position: relative;
  display: inline-block;
  width: 25rem;
  height: 25rem;
  margin-bottom: 4rem;
}
.tabs .tab__icon .bodymovinanim {
  width: 100%;
  height: 100%;
}

.icon {
  width: 100%;
  height: 100%;
  fill: currentColor;
  transition: all 0.2s ease;
}

@media (max-width: 1199px) {
  .container {
    padding: 0 4.5rem;
  }
  .tabs .tab__icon {
    width: 18rem;
    height: 18rem;
    margin-bottom: 3rem;
  }
}

@media (max-width: 991px) {
  .hero .text .line {
    display: inline;
  }
  .hero .text span {
    display: inline;
  }
  .about {
    min-height: inherit;
    padding: 10rem 0;
  }
  .tabs__menu, .tabs__content {
    width: 100%;
  }
  .tabs__menu {
    display: none;
  }
  .tabs .tab {
    min-height: auto;
  }
  .tabs .tab-head-mobile {
    display: block;
    margin-bottom: 2rem;
    text-align: center;
  }
  .tabs .tab__content {
    text-align: center;
    margin-bottom: 6rem;
  }
}

@media (max-width: 767px) {
  h1, .h1 {
    font-size: 4.5rem;
  }
  h2, .h2 {
    font-size: 3.75rem;
  }
  .container {
    padding: 0 2rem;
  }
  .footer {
    padding: 5rem 0;
  }
  .footer .line {
    justify-content: center;
  }
  .footer .line > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  .socials {
    justify-content: center;
  }
  .menu .container > * {
    margin: 3rem 0;
  }
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a:hover {
    text-decoration: none;
  }
}