.App {
  max-width: 500px;
  top: 20px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  color: rgb(0, 0, 0);
  background-color: #ffffff;
}

.AppPrivacy {
  top: 20px;
  max-width: 500px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  font-size: 5pt;
}

.description em {
  color: rgb(104, 84, 105);
  font-style: normal; 
  letter-spacing: 2px;
}


.Wrapper {
  position: relative;
}

.BackgroundGL {
  position: fixed;
  width: 100%;
  height: 100%;
}

.App-logo {
  pointer-events: none;
  height: 130px;
  transform: rotate(-1.81deg)
}

.Game-logo1 {
  height: 82px;
  width:  82px;
  pointer-events: none;
}

.Game-logo2 {
  height: 110.75px;
  width:  102.25px;
  pointer-events: none;
}

.body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background: var(--base-background-color);
  font: var(--base-font-size)/var(--base-line-height) var(--base-font-family);
  min-width: var(--base-min-width);
 }
   

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0s linear;
  }
}

.paragraph-text
{
  letter-spacing: 2px;
}

.header-text
{
  font-family: "NeuePixelGrotesk";
  font-size: 20px;
  display: inline-block;
  width: 95%;
  text-align: left;
}

.Label {
  color: #ffffff;
  font-family: "NeuePixelGrotesk";
  font-size: var(--font-size);
  line-height: 1;
  letter-spacing: 1px;
  display: inline-block;
  padding: 0px 0px 0px 0px;
  position: left;
  top: 0; }
  @media (min-width: 1024px) {
    .Label {
      font-size: var(--font-size); } }

.Game-header 
{
  display: flex;
  align-items: center; 
}

.Paragraph
{
  font-size: var(--font-size);
  letter-spacing: 2px;
}

.App-header 
{
  background-color: #000000;
  min-height: 100vh;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
}

.left-side {
  display: inline-block;
  width: 95%;
  text-align: left;
  font-size: var(--font-size);
}

.left-side-privacy {
  display: inline-block;
  width: 95%;
  text-align: left;
  font-size: 12px;
}

.logoOrient
{
  display: flex;
  margin-right: 10px;
  justify-content: right;
}

.App-link {
  color: #61dafb;
}

.Mail-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}




